import request from "@/utils/request";

export function CollectionList(params) {
    return request({
        url: '/admin/base/collectionList',
        method: 'get',
        params
    })
}
export function CollectionDel(data) {
    return request({
        url: '/admin/base/collectionDel',
        method: 'delete',
        data
    })
}

export function CollectionDetails(params) {
    return request({
        url: '/admin/base/collectionDetails',
        method: 'get',
        params
    })
}

// app信息收集列表
export function CollectionAppList(params) {
    return request({
        url: '/admin/base/collectionAppList',
        method: 'get',
        params
    })
}

// 导出信息收集
export function ExportCollection(params) {
    return request({
        url: "/admin/base/exportCollection",
        method: "get",
        params,
        responseType: 'blob'
    });
}
