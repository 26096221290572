<template>
  <div>
    <el-card>
      <el-form ref="form"  :inline="true">
        <el-form-item label="科目">
           <el-select
            v-model="queryParams.type"
            placeholder="请选择科目"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item,index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="手机号">
           <el-input
          v-model.trim="queryParams.phone"
          placeholder="手机号"
        ></el-input>
        </el-form-item>

        <el-form-item label="名字">
           <el-input
          v-model.trim="queryParams.name"
          placeholder="名字"
        ></el-input>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="toExport">导出</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->

      <el-table :data="collectionList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
         <el-table-column prop="type" label="科目" align="center">
        </el-table-column>
        <el-table-column prop="name" label="名字" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="联系方式"  align="center">
        </el-table-column>
        <el-table-column prop="job" label="职业" align="center">
        </el-table-column>
        <el-table-column prop="channel" label="渠道" align="center">
        </el-table-column>
        <el-table-column prop="class" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="why" label="为什么学" align="center">
        </el-table-column>
         <el-table-column prop="hope" label="希望辅导"  align="center">
        </el-table-column>
        <el-table-column label="时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button type="danger" size="small" icon="el-icon-delete" circle @click="removeInfo(scope.row.id)"></el-button>
          <el-button type="success" size="small" icon="el-icon-info" circle @click="toDetails(scope.row.id)"></el-button>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

     <el-dialog
      :visible.sync="detailsDialog"
      title="详情"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="collectionDetailsList" ref="details">
        <el-form-item el-form-item label="科目：" label-width="100px">
          {{collectionDetailsList.type}}
        </el-form-item>
        <el-form-item el-form-item label="名字：" label-width="100px">
          {{collectionDetailsList.name}}
        </el-form-item>
        <el-form-item el-form-item label="联系方式：" label-width="100px">
          {{collectionDetailsList.phone}}
        </el-form-item>
        <el-form-item el-form-item label="职业：" label-width="100px">
          {{collectionDetailsList.job}}
        </el-form-item>
        <el-form-item el-form-item label="渠道：" label-width="100px">
          {{collectionDetailsList.channel}}
        </el-form-item>
         <el-form-item el-form-item label="班级：" label-width="100px">
          {{collectionDetailsList.class}}
        </el-form-item>
         <el-form-item el-form-item label="为什么学：" label-width="100px">
          {{collectionDetailsList.why}}
        </el-form-item>
        <el-form-item el-form-item label="希望辅导：" label-width="100px">
          {{collectionDetailsList.hope}}
        </el-form-item>
        <el-form-item el-form-item label="时间：" label-width="100px">
            {{$moment(collectionDetailsList.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import {
  CollectionList,
  CollectionDel,
  CollectionDetails,
  ExportCollection,
} from "@/api/information";
export default {
  data() {
    return {
      collectionList: [],
      queryParams: {
        page: 1,
        limit: 10,
        phone: null,
        name: null,
        type: null,
        start_time: null,
        end_time: null,
      },
      total: 0,
      times: null,
      detailsDialog: false,
      collectionDetailsList: {},
      typeList: ["日语", "韩语", "法语"],
    };
  },

  methods: {
    // 导出Excel
    toExport() {
      ExportCollection(this.queryParams).then((res) => {
        console.log(res);
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        });
        console.log(blob);
        const blobUrl = URL.createObjectURL(blob);
        console.log(blobUrl);
        var elink = document.createElement("a");
        elink.style.display = "none";
        elink.href = blobUrl;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },

    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getCollectionList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getCollectionList();
    },

    // 获取全部
    getCollectionList() {
      CollectionList(this.queryParams).then((res) => {
        this.collectionList = res.data.data;
        this.total = res.data.total;
      });
    },
    // 删除
    async removeInfo(i) {
      const result = await this.$confirm("是否要删除该信息?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");

      CollectionDel({ id: i }).then((res) => {
        if (res.code !== 1) {
          this.$message.error("删除失败");
        }
        this.$message.success("删除成功");
        this.getCollectionList();
      });
    },

    toDetails(id) {
      this.detailsDialog = true;
      CollectionDetails({ id: id }).then((res) => {
        this.collectionDetailsList = res.data;
      });
    },

    // 查询
    onSubmit() {
      this.queryParams.page = 1;
      if (this.times == null) {
        this.queryParams.start_time = null;
        this.queryParams.end_time = null;
      }
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getCollectionList();
    },
  },

  created() {
    this.getCollectionList();
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}
</style>
